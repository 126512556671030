(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("vue"), require("tabulator-tables"));
	else if(typeof define === 'function' && define.amd)
		define([, "tabulator-tables"], factory);
	else if(typeof exports === 'object')
		exports["vue-tabulator"] = factory(require("vue"), require("tabulator-tables"));
	else
		root["vue-tabulator"] = factory(root["Vue"], root["Tabulator"]);
})((typeof self !== 'undefined' ? self : this), function(__WEBPACK_EXTERNAL_MODULE__8bbf__, __WEBPACK_EXTERNAL_MODULE_fd94__) {
return 